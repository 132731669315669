
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { VARIANT } from '@goodsidehealth/component-library'
import useTranslation from 'next-translate/useTranslation'
import Head from 'next/head'
import Link from 'next/link'
import { useState } from 'react'
import { Button } from '../components/bits/Button'
import { Label } from '../components/bits/Label'
import Dropdown from '../components/blocks/Dropdown'
import { Header, WELCOME_PAGE } from '../components/composites/Header'
import { useGetOrganizationsQuery } from '../graphql/.generated/frontend'

export default function Home() {
  const { t } = useTranslation('home')
  const { data, isLoading } = useGetOrganizationsQuery()
  const [{ state, organization }, setState] = useState({
    state: '',
    organization: '',
  })

  const states = isLoading
    ? []
    : Array.from(new Set(data?.organizations.map((o) => o.state))).sort()

  const selectOrganization = (state: string) =>
    data?.organizations
      .filter((o) => o.state === state && o.is_active)
      .map((org) => ({ value: org.id.toString(), displayValue: org.name }))
      .sort((a, b) =>
        a.displayValue.toLowerCase().localeCompare(b.displayValue.toLowerCase())
      )

  const organizationSlug = data?.organizations.find(
    (o) => o.id.toString() === organization
  )?.slug

  return (
    <>
      <Head>
        <title>{t('page-title')}</title>
      </Head>
      <div className="flex flex-col min-h-screen bg-pondBlue-dark relative">
        <Header page={WELCOME_PAGE} />
        <img
          className="absolute top-16 h-20 lg:h-48"
          src="/Pattern.svg"
          alt=""
        />
        <main className="flex flex-col mb-auto mx-auto p-8 ">
          <h2 className="text-center text-2.5xl font-black text-white tracking-tight">
            {t('title')}
          </h2>
          <div className="text-sm mt-4 leading-25 px-6 py-8 bg-white rounded-md max-w-2xl mx-auto">
            <p className="text-center text-gray-600">
              {t('legend')}{' '}
              <span className="text-blue-900">{t('legend-contact')}</span>
            </p>

            <div className="max-w-sm mt-6 space-y-4 mx-auto">
              <div>
                <Label htmlFor="state" className="text-gray-600">
                  {t('state')}
                </Label>
                <Dropdown
                  className="w-full"
                  disabled={isLoading}
                  id="associate_org_state"
                  placeholder={t('state-placeholder')}
                  options={states}
                  changeHandler={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      state: e.target.value,
                    }))
                  }}
                />
              </div>
              <div>
                <Label htmlFor="organization" className="text-gray-600">
                  {t('org')}
                </Label>
                <Dropdown
                  className="w-full"
                  disabled={isLoading || !state}
                  id="associate_org_org"
                  placeholder={t('org-placeholder')}
                  options={state ? selectOrganization(state) : []}
                  changeHandler={(e) => {
                    setState((prevState) => ({
                      ...prevState,
                      organization: e.target.value,
                    }))
                  }}
                />
              </div>
              <div>
                <Link href={`/${organizationSlug}/welcome`} passHref>
                  <Button
                    id="associate_org_submit"
                    disabled={!state || !organization}
                    isFullWidth
                    className="bg-gshOrange-default text-pondWhite-lighter hover:bg-gshOrange-default/90"
                    variant={VARIANT.LIGHT}
                  >
                    {t('submit-button')}
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </main>

        <footer className="flex justify-between mt-5">
          <img className="h-20 lg:h-48 ml-0 mr-5" src="/Path.svg" alt="" />
          <img className="h-20 lg:h-48 mx-5" src="/Duck.svg" alt="" />
        </footer>
      </div>
    </>
  )
}


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/index',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  